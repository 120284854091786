import React from 'react';
import { Spin } from 'antd';
import style from './faq.module.scss';
import { Layouts } from '../components/components';
import { QuestionList } from '../modules/modules';
import { StrapiBlocksType, StrapiMainPagesNames } from '../lib/constants/strapi';
import { useStrapiPage } from '../lib/hooks/useStrapiPage';

const TermsPage = () => {
  const { IndexLayout, Page } = Layouts;
  const { currentPage } = useStrapiPage(StrapiMainPagesNames.faq);
  return (
    <IndexLayout>
      <Page>
        {currentPage && currentPage.blocks ? (
          currentPage.blocks.map((block, index) => {
            const blockType = block?.blockTypes?.blockTypes;
            switch (blockType) {
              case StrapiBlocksType.questionList:
                return <QuestionList data={block} key={index} />;
              default:
                return null;
            }
          })
        ) : (
          <div className={style.loader}>
            <Spin />
          </div>
        )}
      </Page>
    </IndexLayout>
  );
};

export default TermsPage;
